<template>
  <div>
    <base-info-card
      :title="strTitle"
      :subtitle="strSubtitle"
      divider
      color="primary1"
    />

    <v-form ref="m_formElement">
      <base-text-field label="Name" required @change="changeName($event)" />

      <base-text-field
        label="Email"
        email
        required
        @change="changeEmail($event)"
      />

      <base-text-field
        label="Subject"
        required
        @change="changeSubject($event)"
      />

      <base-textarea
        class="mb-6"
        label="Your Message"
        name="Message"
        required
        @change="changeMessage($event)"
      />
    </v-form>

    <base-btn color="white" @click="submitEmail()">
      {{ strButtonLabel }}
    </base-btn>

    <k-d-a-submitted-modal
      v-model="g_bContactUsEmailSubmitted"
      :str-title="m_objSubmittedMessage.strTitle"
      :str-text="m_objSubmittedMessage.strText"
      text-color="black"
      @input="toggleModal($event)"
    />
  </div>
</template>

<script>
import APIRequests from "@/mixins/api-requests";

export default {
  name: "KDAContactForm",

  components: {
    KDASubmittedModal: () => import("@/components/KDASubmittedModal"),
  },

  // Injected from the Vuetify Themeable mixin
  mixins: [APIRequests],
  inject: ["theme"],

  props: {
    strButtonLabel: {
      type: String,
      default: "Send Message",
    },
    strSubtitle: {
      type: String,
      default: "",
    },
    strTitle: {
      type: String,
      default: "MAIL US YOUR MESSAGE",
    },
  },

  data: () => ({
    m_objSubmittedMessage: {
      strTitle: "Thank you for contacting us",
      strText: "We will send a reply within 2-3 business days.",
    },
    m_strAnonymousName: "",
    m_strAnonymousEmail: "",
    m_strAnonymousSubject: "",
    m_strAnonymousMessage: "",
  }),

  methods: {
    changeName(anonymousName) {
      if (anonymousName) {
        this.m_strAnonymousName = anonymousName;
      }
    },
    changeEmail(anonymousEmail) {
      if (anonymousEmail) {
        this.m_strAnonymousEmail = anonymousEmail;
      }
    },
    changeSubject(anonymousSubject) {
      if (anonymousSubject) {
        this.m_strAnonymousSubject = anonymousSubject;
      }
    },
    changeMessage(anonymousMessage) {
      if (anonymousMessage) {
        this.m_strAnonymousMessage = anonymousMessage;
      }
    },
    submitEmail() {
      const isValid = this.$refs.m_formElement.validate();
      if (isValid) {
        this.postEmail(
          this.m_strAnonymousName,
          this.m_strAnonymousEmail,
          this.m_strAnonymousSubject,
          this.m_strAnonymousMessage
        );
      }
    },
    toggleModal(bShow) {
      this.$router.go();
    },
  },
};
</script>
